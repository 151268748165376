.slider {
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(129, 126, 126, 0.8);
        z-index: 3;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
}

.transform-wrapper {
    display: flex;
    gap: 15px;
}

.__rcs-handle-root {
    pointer-events: all !important;
}

.close-button {
    position: absolute !important;
    right: 30px;
    top: 30px;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.defects-wrapper {
    display: flex !important;
    flex-wrap: wrap;
    gap: 2.5px;
    margin-right: 55px;
}

.defect {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    min-width: 210px;
    margin-right: 30px;

    &__color {
        width: 20px;
        height: 20px;
    }

    &__title {
        color: white;
    }

    &__amount {
        color: white;
        margin-left: auto;
    }
}

@media (min-width: 1600px) {
    .defect {
        min-width: 260px;
    }
}
