.container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(129, 126, 126, 0.8);
    z-index: 3;
}

.stage {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    background-color: rgba(138, 138, 138, 1);
}

.close-button {
    position: absolute !important;
    right: 30px;
    top: 30px;
    cursor: pointer;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.brightness-contrast-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    align-items: center;
    gap: 15px;
}

.brighness-contarst-setter {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
}
