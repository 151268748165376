.layout {
    background: radial-gradient(50% 50% at 50% 50%, #a3c9ef 15%, #0c87f5 100%);
    background-size: cover;
}

.container {
    max-width: 408px;
    margin: auto;
}

.title {
    text-align: center;
    color: #626262;
    font-size: 30px;
    letter-spacing: -0.04em;
}

.image {
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        padding: 8px;
        background-color: white;
    }
}
