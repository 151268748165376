.timeline {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 6px;

    .createdAt {
        font-size: 12px;
        cursor: default;
    }

    .number {
        cursor: pointer;
    }
}
