.projects {
    &__table-settings {
        label.ant-checkbox-group-item {
            margin-inline-start: 0px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

::-webkit-scrollbar {
    width: 7.5px;
}

::-webkit-scrollbar-track {
    background-color: #e6e6e6;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #141414;
    border-radius: 25px;
}

html {
    scrollbar-color: #141414 #e6e6e6;
    scrollbar-width: thin;
}
